.movie-filter-container {
  padding: 5px 20px;
  margin-top: 20px;
  background: #6c6c6c;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.movie-filter-genre-select-container {
  color: #6c6c6c;
  background-color: #6c6c6c;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
}
.movie-filter-genre__control {
  height: 55px;
  color: #fff !important;
  background-color: #6c6c6c !important;
}

.movie-filter-genre__input {
  color: #fff !important;
}

.movie-filter-genre__single-value {
  color: #fff !important;
}

.movie-filter-genre-select__placeholder {
  color: #fff !important;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.015em;
}

.movie-filter-genre__indicator-separator {
  display: none;
}

@media (min-width: 576px) {
  .movie-filter-container {
    margin: 20px 5px;
  }
}

@media (min-width: 768px) {
  .movie-filter-container {
    margin: 30px 20px;
  }
  .movie-filter-genre__control {
    height: unset;
  }
}

@media (min-width: 1200px) {
  .movie-filter-container {
    height: 80px;
    margin: 30px 10px;
  }
}
