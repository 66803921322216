.main-nav {
  height: 70px;
  padding-left: 18px;
  display: flex;
}
.main-nav div {
  display: flex;
  align-items: center;
}
.nav-logo-text {
  color: #000;
}

.nav-container-navigation {
  margin: 0;
  padding: 0;
}

.nav-navigation-itens {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
