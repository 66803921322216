.btn-logout {
  border: 1px solid #000000;
  border-radius: 10px;
  padding: 0;
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logout {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
}
