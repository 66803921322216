.card-movie {
  background-color: #6c6c6c;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 20px 0;
  margin: 20px 0;
}

.card-movie img {
  width: 100%;
}

h6 {
  padding: 10px 0 0 15px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.015em;
}

.card-movie-year {
  margin: 0 0 0 15px;
  color: #ffc700;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.015em;
}
.subtitle {
  margin: 0 0 0 15px;
  padding-bottom: 50px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.015em;
}

@media (min-width: 576px) {
  .card-movie {
    margin: 5px;
    max-width: 258px;
  }
}

@media (min-width: 768px) {
  .card-movie {
    margin: 10px;
    max-width: 324px;
  }
}

@media (min-width: 992px) {
  .card-movie {
    max-width: 436px;
  }
}

@media (min-width: 1200px) {
  .card-movie {
    max-width: 258px;
  }
}
