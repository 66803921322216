.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  margin-top: 20px;
}

.pagination-item {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #6c6c6c;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.015em;
  margin-left: 10px;
}

.active {
  background-color: #ffc700;
}

.pagination-active{
  background-color: #ffc700;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.arrow{
  padding: 0;
  margin: 0;
}

.arrow-next {
  color: #ffc700; 
  transform: rotate(-180deg);
  margin-left: 10px;
  margin-bottom: 2px;
}

.arrow-inactive {
  color: #9e9e9e;
}

.arrow-active {
  color: #ffc700; 
}
