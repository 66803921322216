h6 {
  margin-left: 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.015em;
  color: #fff;
}

@media (min-width: 576px) {
  .cards {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .container {
    padding: 0;
  }
}

@media (min-width: 992px) {
  .container {
    width: 1120px;
  }
}